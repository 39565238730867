const templateDesktop = document.getElementById("desktop");
const templateMobile = document.getElementById("mobile");
const isMobile = window.matchMedia("(max-width: 1023px)").matches;

const changeDevice = (isMobile) => {
  if (isMobile) {
    const clone = templateMobile.content.cloneNode(true);
    document.body.appendChild(clone);
    return;
  }
  const clone = templateDesktop.content.cloneNode(true);
  document.body.appendChild(clone);
};
const handleResize = (e) => {
  if (window.matchMedia("(max-width: 1023px)").matches !== isMobile) {
    location.reload();
  }
};
window.addEventListener("resize", handleResize);
changeDevice(isMobile);
